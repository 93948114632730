import applicationColors from '@Enums/applicationColors';
import chipColor from '@Models/chip-colors';

export const useColors = () => {
  const primary: string = applicationColors.primary;

  const setChipColor = (data: string) => {
    const setColor: {[key: string]: string} = {
      ['FORMAL_DEFECT']: chipColor.FORMAL_DEFECT,
      ['ACCEPTED']: chipColor.ACCEPTED,
      ['REQUEST']: chipColor.REQUEST,
      ['USER_REQUEST']: chipColor.USER_REQUEST,
      ['DEFAULT']: chipColor.DEFAULT
    };
    return setColor[data];
  }

  const onLightSurfaceLevel0 = () => {
    return applicationColors.onLightSurfaceLevel0 as string;
  }

  return {
    primary,
    setChipColor,
    onLightSurfaceLevel0
  }
}